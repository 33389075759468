<template>
  <div>

    <section id="dashboard" class="mt-4">
      <div class="container">
        <div class="row">
          <SideNav v-bind:selected='0'/>
          
          <div class="col-12">
            <div class="row mt-4">
              <div class="col mt-2">
                <strong>Users</strong>
              </div>
            </div>

            <table class="table">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Username</th>
                    <th scope="col">Address</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Dob</th>
                    <th scope="col">Banned</th>
                    </tr>
                </thead>
                <tbody v-for="user in users" :key="user.username">
                    <tr>
                    <th scope="row">{{users.indexOf(user)}}</th>
                    <td>{{user.firstName}} {{user.lastName}}</td>
                    <td>{{user.username}}  £{{user.wallet.balance.toFixed(2)}}</td>
                    <td>{{user.address}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.dob}}</td>
                    <td>
                        <button type="submit" @click="balanceAdd(user.id)" class="btn btn-custom mb-2">Add Balance</button>
                        <button type="submit" @click="banUser(user.id)" class="btn btn-custom mb-2">Ban</button>
                        <button type="submit" @click="unbanUser(user.id)" class="btn btn-custom mb-2">Unban</button>
                        <button type="submit" @click="usersConfirm(user.id)" class="btn btn-custom">Verify</button>
                    </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>

  <Footer />
</template>

<script>
import Api from '../../scripts/api'

import SideNav from '../../components/admin/SideNavigator'
import Footer from '../../components/global/Footer'

export default {
  components: {
    SideNav,
    Footer
  },
  props: {
    account: Object
  },
  methods: {
    async balanceAdd(user) {
      var amount = prompt('Enter amount to add ar substract, by providing - negative for taking away');
      if(!amount) return;

      var result = await Api.admin.usersBalanceAdd(user, amount);
      if(result.status == 404) return this.$toast.warning('Something went wrong.');
      if(result.status == 410) return this.$toast.warning('No such user.');

      if(result.status == 200) {
        return this.$toast.success('Balance Updated');
      }
    },
    async banUser(user) {
        await Api.admin.usersBan(user)
    },
    async unbanUser(user) {
        await Api.admin.usersUnban(user)
    },
    async usersConfirm(user) {
        await Api.admin.usersConfirm(user)
    }
  },
  data() {
    return {
      perPage: 3,
      currentPage: 1,
      users: []
    }
  },
  computed: {
    rows() {
        return this.users.length
    }
  },
  async created() {
      var isAdmin = await Api.admin.isAdmin();
      if(isAdmin.status != 200) {
        this.$router.push('/');
      }

    //   var ban = await Api.admin.usersBan();
      var users = await Api.admin.usersGetAll();

      if(users.status === 200) {
        this.users = await users.json();
      }
      else {
        this.$toast.error('Could not retrieve users.')
      }


    }
};
</script>
